@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "SpaceGrotesk";
  src: url("./fonts/SpaceGrotesk.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter.ttf");
}

:root {
  --color-accent: #c96a62;
}

.headline-gradient {
  background: linear-gradient(90.05deg, #FFAC0C 0.01%, #DD0EFF 20.43%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
color: transparent;
}

.project-background-gradient {
  background: linear-gradient(45deg, #070B15 0%, #2E3641 100%);
}

.special-button-gradient {
  background: linear-gradient(45deg, #42307D 0%, #7F56D9 100%);
}

.button-heroicon-stroke > path {
  stroke-width: 2px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
